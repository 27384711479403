import { useCallback, useEffect, useState } from "react";
import AppStatusSnackbar, {
    Severity,
} from "./components/AppStatusSnackbar/AppStatusSnackbar";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { SettingsDrawer } from "./components/SettingsDrawer/SettingsDrawer";
import { appColors } from "./styles";
import { RandomListPage } from "./components/RandomListPage/RandomListPage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
    getCheckedFavouritedListItems,
    getCheckedListItems,
    List,
} from "./Models/List";
import { Footer } from "./components/Footer/Footer";

function App() {
    // App state
    const [isCookiesAccepted, setIsCookiesAccepted] = useState<boolean>(
        getCookieConsentValue("chancemylist_cookie_consent") === "true"
    );
    const [isCookiesConsentReset, setIsCookiesConsentReset] =
        useState<boolean>(false);

    const [lists, setLists] = useState<List[]>(
        localStorage.getItem("lists")
            ? JSON.parse(localStorage.getItem("lists")!)
            : [
                  {
                      name: "My List",
                      items: [],
                      favouritesMultiplier: 1,
                      isFavouritesMultiplierEnabled: false,
                      isHideUnselectedListItemsEnabled: false,
                      isRemoveRandomListItemEnabled: false,
                  },
              ]
    );

    const [currentList, setCurrentList] = useState<List>(lists[0]);

    const [randomListItem, setRandomListItem] = useState<string>("...?");
    const [isGenerating, setIsGenerating] = useState<boolean>(false);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [alert, setAlert] = useState<
        { severity: Severity; message: string } | undefined
    >(undefined);

    // Chance display functions
    const getChanceNextListItemIsFavourite = useCallback(() => {
        const amountOfCheckedFavouritedListItems =
            getCheckedFavouritedListItems(currentList).length;
        if (amountOfCheckedFavouritedListItems === 0) return 0;
        const amountOfCheckedListItems =
            getCheckedListItems(currentList).length;
        const amountOfListItemsToRandomise =
            currentList.isFavouritesMultiplierEnabled
                ? amountOfCheckedListItems +
                  (currentList.favouritesMultiplier - 1) *
                      amountOfCheckedFavouritedListItems
                : amountOfCheckedListItems;
        const amountOfFavouriteListItemsInRandomisation =
            currentList.isFavouritesMultiplierEnabled
                ? amountOfCheckedFavouritedListItems *
                  currentList.favouritesMultiplier
                : amountOfCheckedFavouritedListItems;
        const chanceOfFavouriteListItem = Number(
            Number(
                (amountOfFavouriteListItemsInRandomisation /
                    amountOfListItemsToRandomise) *
                    100
            ).toFixed(2)
        );
        return chanceOfFavouriteListItem;
    }, [currentList]);

    const [chanceNextListItemIsFavourite, setChanceNextListItemIsFavourite] =
        useState<number>(getChanceNextListItemIsFavourite());

    useEffect(() => {
        setChanceNextListItemIsFavourite(getChanceNextListItemIsFavourite());
    }, [currentList, getChanceNextListItemIsFavourite]);

    const showSnackbar = (severity: Severity, message: string) => {
        setAlert({
            severity: severity,
            message: message,
        });
        setSnackbarOpen(true);
    };

    const handleSetLists = (value: List[]) => {
        setLists(value);
        if (isCookiesAccepted) {
            localStorage.setItem("lists", JSON.stringify(value));
        }
    };

    const handleSetCurrentList = (value: List, index?: number) => {
        setCurrentList(value);

        const newLists =
            index !== undefined
                ? lists.map((list, i) => (index === i ? value : list))
                : lists.map((list) =>
                      list.name === value.name ? value : list
                  );
        handleSetLists(newLists);
    };

    return (
        <div id="app">
            <div
                style={{
                    backgroundColor: appColors.mediumGrey,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "calc(10px + 6vmin)",
                    color: appColors.white,
                    textAlign: "center",
                    minHeight: "100vh",
                }}
            >
                <SettingsDrawer
                    currentList={currentList}
                    handleSetCurrentList={handleSetCurrentList}
                    lists={lists}
                    handleSetLists={handleSetLists}
                    setIsCookiesConsentReset={setIsCookiesConsentReset}
                    isCookiesConsentReset={isCookiesConsentReset}
                    showSnackbar={showSnackbar}
                    chanceNextListItemIsFavourite={
                        chanceNextListItemIsFavourite
                    }
                />
                <BrowserRouter>
                    <Routes>
                        <Route
                            path={`/list`}
                            Component={() => (
                                <RandomListPage
                                    randomListItem={randomListItem}
                                    isGenerating={isGenerating}
                                    setIsGenerating={setIsGenerating}
                                    currentList={currentList}
                                    handleSetCurrentList={handleSetCurrentList}
                                    setRandomListItem={setRandomListItem}
                                    showSnackbar={showSnackbar}
                                    chanceNextListItemIsFavourite={
                                        chanceNextListItemIsFavourite
                                    }
                                />
                            )}
                        />
                        <Route
                            path="*"
                            element={<Navigate to={`/list`} replace={true} />}
                        />
                    </Routes>
                </BrowserRouter>
                <AppStatusSnackbar
                    open={snackbarOpen}
                    setOpen={setSnackbarOpen}
                    alert={alert}
                />
                <CookieConsent
                    overlayStyle={{ background: "rgba(0, 0, 0, 0.8)" }}
                    location="bottom"
                    buttonText="Accept"
                    declineButtonText="Decline"
                    style={{ background: appColors.darkGrey }}
                    buttonStyle={{
                        fontSize: "15px",
                    }}
                    declineButtonStyle={{ fontSize: "15px" }}
                    enableDeclineButton
                    contentStyle={{
                        display: "flex",
                        marginBottom: "50px",
                    }}
                    overlay
                    cookieName="chancemylist_cookie_consent"
                    visible={isCookiesConsentReset ? "show" : "byCookieValue"}
                    onAccept={() => {
                        setIsCookiesAccepted(true);
                        setIsCookiesConsentReset(false);
                    }}
                    onDecline={() => {
                        setIsCookiesAccepted(false);
                        setIsCookiesConsentReset(false);
                        localStorage.clear();
                    }}
                >
                    <div>
                        <p style={{ fontSize: "1rem" }}>
                            This website stores your list data in your browser's
                            storage. Your list data is strictly stored in your
                            browser's storage for the purpose of generating
                            random items and is not sent to any server. If you
                            do not consent to this, you can still use the
                            website but your list data will not be saved when
                            refreshing the page or navigating away. By clicking
                            "Accept" you consent to storing your list data in
                            your browser's storage. A cookie will be stored in
                            your browser to remember your consent.
                        </p>
                        <p style={{ fontSize: "1rem" }}>
                            It is highly recommended to backup your data via the
                            "Export" feature in the settings drawer as the app
                            is still in beta and data loss may occur.
                        </p>
                    </div>
                </CookieConsent>
                <Footer />
            </div>
        </div>
    );
}

export default App;
