export type ListItem = {
    name: string;
    checked: boolean;
    favourited: boolean;
};

export type List = {
    name: string;
    items: ListItem[];
    isFavouritesMultiplierEnabled: boolean;
    favouritesMultiplier: number;
    isRemoveRandomListItemEnabled: boolean;
    isHideUnselectedListItemsEnabled: boolean;
};

export function getCheckedListItems(list: List): ListItem[] {
    return list.items.filter((item) => item.checked);
}

export function getFavouritedListItems(list: List): ListItem[] {
    return list.items.filter((item) => item.favourited);
}

export function getCheckedFavouritedListItems(list: List): ListItem[] {
    return list.items.filter((item) => item.checked && item.favourited);
}
