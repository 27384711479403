import { appColors } from "../../styles";

const appVersion = "0.1.0 (beta)";

export const Footer = () => {
    return (
        <div
            className="page-footer"
            style={{
                boxShadow: "5px 5px 15px black",
                width: "100%",
                backgroundColor: appColors.darkGrey,
                height: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                marginTop: "50px",
            }}
        >
            <div style={{ fontSize: "1.5rem", paddingTop: 100 }}>
                Have feature suggestions or feedback? Email me at:{" "}
                <a
                    style={{
                        color: appColors.blue,
                        textDecoration: "none",
                    }}
                    href="mailto:paperguin@gmail.com"
                >
                    paperguin@gmail.com
                </a>
            </div>
            <div
                style={{
                    fontSize: "1.5rem",
                    paddingTop: 50,
                    fontStyle: "italic",
                    opacity: 0.7,
                }}
            >
                version {appVersion}
            </div>
            <div style={{ fontSize: "2rem", paddingBottom: 50 }}>
                A{" "}
                <a
                    style={{
                        color: appColors.blue,
                        textDecoration: "none",
                    }}
                    href="https://www.raveenmaldeni.com/"
                >
                    paperguin
                </a>{" "}
                project
            </div>
        </div>
    );
};
