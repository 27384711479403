export const appColors = {
    lightGrey: "#43474E",
    mediumGrey: "#282c34",
    darkGrey: "#282729",
    almostWhite: "#E1E5F2",
    white: "#FFFFFF",
    lightGreen: "#9CD08F",
    lightRed: "#D64045",
    lightBlue: "#BFDBF7",
    lightYellow: "#F0C987",
    lightPurple: "#B392AC",
    blue: "#5995ED",
};

export const appStyles = {
    button: {
        sx: { backgroundColor: appColors.lightGrey, color: "white" },
        marginSpace: { marginLeft: "10px" },
    },
    divider: {
        sx: { borderColor: appColors.white, marginBottom: 2, marginTop: 5 },
    },
};
