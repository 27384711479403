import {
    IconButton,
    ListItem,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { drawerWidth } from "../SettingsDrawer/SettingsDrawer";
import { List as ListType } from "../../Models/List";
import { appColors } from "../../styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Severity } from "../AppStatusSnackbar/AppStatusSnackbar";

export const ListOfLists = (props: {
    lists: ListType[];
    handleSetLists: (lists: ListType[]) => void;
    currentList: ListType;
    handleSetCurrentList: (value: ListType, index: number) => void;
    showSnackbar: (severity: Severity, message: string) => void;
}) => {
    const [editList, setEditList] = useState(false);
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number
    ) => {
        const selectedList = props.lists[index];
        props.handleSetCurrentList(selectedList, index);
    };

    const handleAddList = () => {
        const newList: ListType = {
            name: "New List",
            items: [],
            favouritesMultiplier: 1,
            isFavouritesMultiplierEnabled: false,
            isRemoveRandomListItemEnabled: false,
            isHideUnselectedListItemsEnabled: false,
        };
        let listEndNumber = 2;
        while (props.lists.find((list) => list.name === newList.name)) {
            newList.name =
                newList.name.length === 8
                    ? `New List ${listEndNumber}`
                    : `${newList.name.slice(
                          0,
                          newList.name.lastIndexOf(" ")
                      )} ${listEndNumber}`;
            listEndNumber++;
        }
        props.handleSetCurrentList(newList, props.lists.length);
        props.handleSetLists([...props.lists, newList]);
        props.showSnackbar("success", "New List added!");
    };

    const handleRemoveList = (list: ListType) => {
        const newLists = props.lists.filter((l) => l.name !== list.name);
        if (props.currentList.name === list.name) {
            props.handleSetCurrentList(newLists[0], 0);
        }
        props.handleSetLists(newLists);
        props.showSnackbar("success", `${list.name} removed!`);
    };

    const handleEditListName = (list: ListType, index: number) => {
        props.handleSetCurrentList(list, index);
    };

    return (
        <div style={{ width: "100%", maxWidth: drawerWidth - 30 }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography variant="h6">Lists</Typography>

                <span
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        right: 0,
                    }}
                >
                    <Tooltip title="Add list" placement="top">
                        <IconButton
                            type="button"
                            sx={{ color: appColors.lightGreen }}
                            aria-label="add list"
                            onClick={handleAddList}
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit list" placement="top">
                        <IconButton
                            type="button"
                            sx={{
                                color: appColors.lightGreen,
                            }}
                            aria-label="edit list"
                            onClick={() => setEditList(!editList)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </span>
            </div>
            <div style={{ width: "100%" }}>
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <List component="nav">
                        {props.lists.map((list, index) => (
                            <ListItem
                                key={list.name}
                                secondaryAction={
                                    editList && (
                                        <Tooltip
                                            title="Delete list"
                                            placement="top"
                                        >
                                            <IconButton
                                                edge="end"
                                                size="large"
                                                style={{
                                                    display: "flex",
                                                    color:
                                                        props.lists.length === 1
                                                            ? appColors.darkGrey
                                                            : appColors.lightRed,
                                                }}
                                                aria-label="delete list"
                                                onClick={() =>
                                                    handleRemoveList(list)
                                                }
                                                disabled={
                                                    props.lists.length === 1
                                                }
                                            >
                                                <RemoveCircleIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                            >
                                <ListItemButton
                                    key={list.name}
                                    selected={
                                        props.currentList.name === list.name
                                    }
                                    onClick={(event) =>
                                        handleListItemClick(event, index)
                                    }
                                >
                                    {editList ? (
                                        <TextField
                                            sx={{
                                                "& .MuiInput-underline:before":
                                                    {
                                                        borderBottomColor:
                                                            appColors.almostWhite,
                                                    },
                                                "& .MuiInput-underline:after": {
                                                    borderBottomColor:
                                                        appColors.lightPurple,
                                                },
                                            }}
                                            onBlur={(e) => {
                                                let listName =
                                                    e.target.value.trim();

                                                if (listName === "") {
                                                    listName = "New List";
                                                }
                                                let listEndNumber = 2;
                                                while (
                                                    props.lists.find(
                                                        (list) =>
                                                            list.name ===
                                                            listName
                                                    )
                                                ) {
                                                    listName =
                                                        listName.length === 8
                                                            ? `New List ${listEndNumber}`
                                                            : `${listName.slice(
                                                                  0,
                                                                  listName.lastIndexOf(
                                                                      " "
                                                                  )
                                                              )} ${listEndNumber}`;
                                                    listEndNumber++;
                                                }
                                                handleEditListName(
                                                    {
                                                        ...list,
                                                        name: listName,
                                                    },
                                                    index
                                                );
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: appColors.almostWhite,
                                                },
                                            }}
                                            hiddenLabel
                                            defaultValue={list.name}
                                            variant="standard"
                                            size="medium"
                                        />
                                    ) : (
                                        <ListItemText primary={list.name} />
                                    )}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </div>
        </div>
    );
};
