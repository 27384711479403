import ItemList from "../ItemList/ItemList";
import { ListSelectionControls } from "../ListSelectionControls/ListSelectionControls";
import { Severity } from "../AppStatusSnackbar/AppStatusSnackbar";
import { List } from "../../Models/List";
import { RandomResultDisplay } from "../RandomResultDisplay/RandomResultDisplay";

type RandomListPageProps = {
    randomListItem: string;
    isGenerating: boolean;
    currentList: List;
    handleSetCurrentList: (list: List) => void;
    setIsGenerating: (isGenerating: boolean) => void;
    setRandomListItem: (randomListItem: string) => void;
    showSnackbar: (severity: Severity, message: string) => void;
    chanceNextListItemIsFavourite: number;
};

export const RandomListPage = (props: RandomListPageProps) => {
    return (
        <>
            <RandomResultDisplay
                randomListItem={props.randomListItem}
                isGenerating={props.isGenerating}
                setIsGenerating={props.setIsGenerating}
                setRandomListItem={props.setRandomListItem}
                currentList={props.currentList}
                handleSetCurrentList={props.handleSetCurrentList}
                chanceNextListItemIsFavourite={
                    props.chanceNextListItemIsFavourite
                }
            />

            <ListSelectionControls
                currentList={props.currentList}
                handleSetCurrentList={props.handleSetCurrentList}
            />
            <ItemList
                currentList={props.currentList}
                handleSetCurrentList={props.handleSetCurrentList}
                showSnackbar={props.showSnackbar}
            />
        </>
    );
};
