import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export type Severity = "error" | "warning" | "info" | "success";

export default function AppStatusSnackbar(props: {
    open: boolean;
    setOpen: (value: boolean) => void;
    alert:
        | {
              severity: Severity;
              message: string;
          }
        | undefined;
}) {
    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        props.setOpen(false);
    };

    return (
        <div>
            <Snackbar
                open={props.open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={props.alert?.severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {props.alert?.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
