import { Button, styled, Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { resetCookieConsentValue } from "react-cookie-consent";
import { appStyles } from "../../styles";
import { List } from "../../Models/List";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

export const DataManagement = (props: {
    lists: List[];
    handleSetLists: (value: List[]) => void;
    setIsCookiesConsentReset: (value: boolean) => void;
    setDrawerOpen: (value: boolean) => void;
}) => {
    const onExportData = () => {
        // Prepare the data
        const data = {
            lists: props.lists,
        };
        const json = JSON.stringify(data);

        // Create a blob of the data
        const blob = new Blob([json], { type: "application/json" });
        const url = URL.createObjectURL(blob);

        // Create a link for the download
        const link = document.createElement("a");
        link.download = "chanceMyListData.json";
        link.href = url;
        link.click();
    };

    function handleDataImport(event: any) {
        const reader = new FileReader();
        reader.onload = function (e) {
            const json: {
                lists: List[];
            } = JSON.parse(e.target?.result as string);
            props.handleSetLists(json.lists);
        };
        if (event.target.files[0]) {
            reader.readAsText(event.target.files[0]);
        }
        event.target.value = "";
    }

    return (
        <div>
            <Typography variant="h6">Data Management</Typography>
            <div style={{ fontSize: "calc(10px + 3vmin)" }}>
                <Button
                    variant="contained"
                    color="primary"
                    tabIndex={-1}
                    sx={appStyles.button.sx}
                    startIcon={<CloudUploadIcon />}
                >
                    Import Data
                    <VisuallyHiddenInput
                        type="file"
                        onChange={handleDataImport}
                    />
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FileDownloadIcon />}
                    sx={appStyles.button.sx}
                    onClick={onExportData}
                    style={appStyles.button.marginSpace}
                >
                    Export Data
                </Button>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                    onClick={() => {
                        resetCookieConsentValue("chancemylist_cookie_consent");
                        props.setIsCookiesConsentReset(true);
                        props.setDrawerOpen(false);
                    }}
                    variant="contained"
                    color="primary"
                    sx={appStyles.button.sx}
                >
                    Reset Local Storage Consent
                </Button>
            </div>
        </div>
    );
};
