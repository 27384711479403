import {
    FormControlLabel,
    FormGroup,
    Grid,
    Input,
    Slider,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import CasinoIcon from "@mui/icons-material/Casino";
import { appColors } from "../../styles";
import { List } from "../../Models/List";
import { FavouritesChanceMessage } from "../FavouritesChanceMessage/FavouritesChanceMessage";

export const FavouritesMultiplier = (props: {
    currentList: List;
    handleSetCurrentList: (value: List) => void;
    chanceNextListItemIsFavourite: number;
}) => {
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        props.handleSetCurrentList({
            ...props.currentList,
            favouritesMultiplier: newValue as number,
        });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handleSetCurrentList({
            ...props.currentList,
            favouritesMultiplier:
                event.target.value === "" ? 1 : Number(event.target.value),
        });
    };

    const handleBlur = () => {
        if (props.currentList.favouritesMultiplier < 1) {
            props.handleSetCurrentList({
                ...props.currentList,
                favouritesMultiplier: 1,
            });
        } else if (props.currentList.favouritesMultiplier > 100) {
            props.handleSetCurrentList({
                ...props.currentList,
                favouritesMultiplier: 100,
            });
        }
    };

    return (
        <div style={{ width: 250 }}>
            <Typography variant="h6" gutterBottom>
                Favourites Multiplier
            </Typography>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    fontSize: "calc(10px + 4vmin)",
                }}
            >
                <FormGroup onSubmit={(e) => e.preventDefault()}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={
                                    props.currentList
                                        .isFavouritesMultiplierEnabled
                                }
                                onChange={() =>
                                    props.handleSetCurrentList({
                                        ...props.currentList,
                                        isFavouritesMultiplierEnabled:
                                            !props.currentList
                                                .isFavouritesMultiplierEnabled,
                                    })
                                }
                            />
                        }
                        label={
                            props.currentList.isFavouritesMultiplierEnabled
                                ? `Enabled`
                                : `Disabled`
                        }
                    />
                </FormGroup>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <Tooltip
                            title={
                                props.currentList.favouritesMultiplier === 1
                                    ? "Favourites are just as likely to appear as other items"
                                    : `Favourites are ${props.currentList.favouritesMultiplier} times more likely to be selected`
                            }
                        >
                            <CasinoIcon />
                        </Tooltip>
                    </Grid>
                    <Grid item xs>
                        <Slider
                            min={1}
                            max={100}
                            value={props.currentList.favouritesMultiplier}
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                        />
                    </Grid>

                    <Grid item>
                        <Input
                            sx={{
                                color: appColors.almostWhite,
                                backgroundColor: appColors.lightGrey,
                                borderRadius: 1,
                                paddingLeft: "5px",
                            }}
                            value={props.currentList.favouritesMultiplier}
                            size="small"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                                step: 1,
                                min: 1,
                                max: 100,
                                type: "number",
                                "aria-labelledby": "input-slider",
                            }}
                        />
                    </Grid>
                </Grid>

                <FavouritesChanceMessage
                    chanceNextListItemIsFavourite={
                        props.chanceNextListItemIsFavourite
                    }
                />
            </div>
        </div>
    );
};
