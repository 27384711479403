import { Button } from "@mui/material";
import { appStyles } from "../../styles";
import { List, ListItem } from "../../Models/List";

export const ListSelectionControls = (props: {
    currentList: List;
    handleSetCurrentList: (list: List) => void;
}) => {
    return (
        <div>
            <Button
                sx={appStyles.button.sx}
                size="large"
                variant="contained"
                color="primary"
                onClick={() =>
                    props.handleSetCurrentList({
                        ...props.currentList,
                        items: props.currentList.items.map(
                            (item: ListItem) => ({
                                ...item,
                                checked: true,
                            })
                        ),
                    })
                }
            >
                Select All
            </Button>
            <Button
                sx={appStyles.button.sx}
                size="large"
                variant="contained"
                color="primary"
                onClick={() =>
                    props.handleSetCurrentList({
                        ...props.currentList,
                        items: props.currentList.items.map((item) => ({
                            ...item,
                            checked: false,
                        })),
                    })
                }
                style={appStyles.button.marginSpace}
            >
                Select None
            </Button>
            <Button
                sx={appStyles.button.sx}
                size="large"
                variant="contained"
                color="primary"
                onClick={() =>
                    props.handleSetCurrentList({
                        ...props.currentList,
                        items: props.currentList.items.map((item) => ({
                            ...item,
                            checked: item.favourited,
                        })),
                    })
                }
                style={appStyles.button.marginSpace}
            >
                Select Favourites
            </Button>
        </div>
    );
};
