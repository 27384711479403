import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FavouritesMultiplier } from "../FavouritesMultiplier/FavouritesMultiplier";
import { DataManagement } from "../DataManagement/DataManagement";
import { CurrentListSettings } from "../CurrentListSettings/CurrentListSettings";
import { getCookieConsentValue } from "react-cookie-consent";
import { appColors, appStyles } from "../../styles";
import { ListOfLists } from "../ListOfLists/ListOfLists";
import { List } from "../../Models/List";
import { Severity } from "../AppStatusSnackbar/AppStatusSnackbar";

export const drawerWidth = 350;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export function SettingsDrawer(props: {
    currentList: List;
    handleSetCurrentList: (value: List, index?: number) => void;
    lists: List[];
    handleSetLists: (value: List[]) => void;
    setIsCookiesConsentReset: (value: boolean) => void;
    isCookiesConsentReset: boolean;
    showSnackbar: (severity: Severity, message: string) => void;
    chanceNextListItemIsFavourite: number;
}) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar sx={{ backgroundColor: appColors.darkGrey }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            mr: 2,
                            ...(open && { display: "none" }),
                        }}
                        disabled={
                            props.isCookiesConsentReset ||
                            getCookieConsentValue(
                                "chancemylist_cookie_consent"
                            ) === undefined
                        }
                    >
                        <MenuIcon />
                    </IconButton>
                    {!open && (
                        <span style={{ fontSize: "2rem" }}>Settings</span>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                PaperProps={{
                    sx: {
                        backgroundColor: appColors.mediumGrey,
                        color: appColors.white,
                    },
                }}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton
                        onClick={handleDrawerClose}
                        sx={{ color: appColors.white }}
                    >
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider
                    sx={{ borderColor: appColors.white, marginBottom: 2 }}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <ListOfLists
                        currentList={props.currentList}
                        handleSetCurrentList={props.handleSetCurrentList}
                        lists={props.lists}
                        handleSetLists={props.handleSetLists}
                        showSnackbar={props.showSnackbar}
                    />
                </div>
                <Divider sx={appStyles.divider.sx} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <FavouritesMultiplier
                        currentList={props.currentList}
                        handleSetCurrentList={props.handleSetCurrentList}
                        chanceNextListItemIsFavourite={
                            props.chanceNextListItemIsFavourite
                        }
                    />
                </div>

                <Divider sx={appStyles.divider.sx} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <CurrentListSettings
                        currentList={props.currentList}
                        handleSetCurrentList={props.handleSetCurrentList}
                    />
                </div>

                <Divider sx={appStyles.divider.sx} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <DataManagement
                        lists={props.lists}
                        handleSetLists={props.handleSetLists}
                        setIsCookiesConsentReset={
                            props.setIsCookiesConsentReset
                        }
                        setDrawerOpen={setOpen}
                    />
                </div>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
            </Main>
        </Box>
    );
}
