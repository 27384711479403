import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import { List } from "../../Models/List";

export const CurrentListSettings = (props: {
    currentList: List;
    handleSetCurrentList: (value: List) => void;
}) => {
    return (
        <div>
            <Typography variant="h6" gutterBottom>
                List Settings
            </Typography>
            <div>
                <FormGroup onSubmit={(e) => e.preventDefault()}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    props.currentList
                                        .isRemoveRandomListItemEnabled
                                }
                                onChange={(e) =>
                                    props.handleSetCurrentList({
                                        ...props.currentList,
                                        isRemoveRandomListItemEnabled:
                                            e.target.checked,
                                    })
                                }
                            />
                        }
                        label="Uncheck item after rolling"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    props.currentList
                                        .isHideUnselectedListItemsEnabled
                                }
                                onChange={(e) =>
                                    props.handleSetCurrentList({
                                        ...props.currentList,
                                        isHideUnselectedListItemsEnabled:
                                            e.target.checked,
                                    })
                                }
                            />
                        }
                        label="Hide unselected items"
                    />
                </FormGroup>
            </div>
        </div>
    );
};
