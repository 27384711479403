import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { InputBase, Paper, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState } from "react";
import { Severity } from "../AppStatusSnackbar/AppStatusSnackbar";
import EditIcon from "@mui/icons-material/Edit";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { appColors } from "../../styles";
import { List as ListType, ListItem as ListItemType } from "../../Models/List";

export default function ItemList(props: {
    currentList: ListType;
    handleSetCurrentList: (value: ListType) => void;
    showSnackbar: (severity: Severity, message: string) => void;
}) {
    const [newListItemInput, setNewListItemInput] = useState("");
    const [editList, setEditList] = useState(false);

    const handleListItemChange = (value: ListItemType) => () => {
        props.handleSetCurrentList({
            ...props.currentList,
            items: props.currentList.items.map((item) =>
                item.name === value.name ? value : item
            ),
        });
    };

    const checkedFilteredItems: ListItemType[] = props.currentList
        .isHideUnselectedListItemsEnabled
        ? props.currentList.items.filter((item) => item.checked)
        : props.currentList.items;

    const inputFilteredItems: ListItemType[] = checkedFilteredItems.filter(
        (item) =>
            item.name.toLowerCase().includes(newListItemInput.toLowerCase())
    );

    const handleAddListItem = () => {
        const newListItem = newListItemInput.trim();
        if (newListItem !== "") {
            if (
                !props.currentList.items.some(
                    (ListItem: ListItemType) =>
                        ListItem.name.toLowerCase() ===
                        newListItem.toLowerCase()
                )
            ) {
                props.handleSetCurrentList({
                    ...props.currentList,
                    items: [
                        ...props.currentList.items,
                        { name: newListItem, checked: true, favourited: false },
                    ].sort((a, b) => a.name.localeCompare(b.name)),
                });
                setNewListItemInput("");
                props.showSnackbar("success", `${newListItem} added!`);
            } else {
                setNewListItemInput("");
                props.showSnackbar("warning", `${newListItem} already exists!`);
            }
        }
    };

    const handleRemoveListItem = (itemToRemove: ListItemType) => {
        const updatedItems = props.currentList.items.filter(
            (item) => item.name !== itemToRemove.name
        );
        props.handleSetCurrentList({
            ...props.currentList,
            items: updatedItems,
        });
        props.showSnackbar("success", `${itemToRemove.name} removed!`);
    };

    return (
        <>
            <div
                style={{
                    backgroundColor: appColors.mediumGrey,
                    padding: 10,
                    top: 64,
                    position: "sticky",
                    backgroundPosition: "5%",
                    zIndex: 1,
                }}
            >
                <div
                    style={{
                        width: 350,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Paper
                        sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: 350,

                            backgroundColor: appColors.lightGrey,
                        }}
                    >
                        <InputBase
                            sx={{
                                ml: 1,
                                flex: 1,
                                color: appColors.almostWhite,
                            }}
                            autoFocus={true}
                            placeholder="Add item..."
                            value={newListItemInput}
                            onChange={(e) => {
                                setNewListItemInput(e.target.value);
                            }}
                            inputProps={{ "aria-label": "add item input" }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleAddListItem();
                                }
                            }}
                        />
                        <Tooltip title="Add item" placement="top">
                            <IconButton
                                type="button"
                                sx={{ p: "10px", color: appColors.lightGreen }}
                                aria-label="add item"
                                onClick={handleAddListItem}
                            >
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                    <Tooltip title="Edit list" placement="top">
                        <IconButton
                            type="button"
                            style={{ marginLeft: "10px" }}
                            sx={{
                                color: appColors.lightGreen,
                                backgroundColor: appColors.lightGrey,
                                borderRadius: 2,
                                padding: 1.5,
                            }}
                            aria-label="edit list"
                            onClick={() => setEditList(!editList)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Back to top" placement="top">
                        <IconButton
                            type="button"
                            style={{ marginLeft: "10px" }}
                            sx={{
                                color: appColors.lightGreen,
                                backgroundColor: appColors.lightGrey,
                                borderRadius: 2,
                                padding: 1.5,
                            }}
                            aria-label="go to page top"
                            onClick={() =>
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                })
                            }
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {inputFilteredItems.length === 0 ? (
                <p
                    style={{
                        fontSize: "1.3rem",
                        fontStyle: "italic",
                    }}
                >
                    Press Enter to add{" "}
                    <span
                        style={{
                            color: appColors.lightGreen,
                            fontWeight: "bold",
                        }}
                    >
                        {newListItemInput}
                    </span>{" "}
                    to the list!
                </p>
            ) : (
                <List
                    style={{ marginTop: 20 }}
                    sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: appColors.lightGrey,
                        borderRadius: 2,
                    }}
                >
                    {inputFilteredItems.map((item) => {
                        const labelId = `checkbox-list-label-${item}`;

                        return (
                            <ListItem
                                key={item.name}
                                secondaryAction={
                                    <div style={{ display: "flex" }}>
                                        {!editList && (
                                            <Tooltip
                                                title={
                                                    item.favourited
                                                        ? "Unfavourite"
                                                        : "Favourite"
                                                }
                                                placement="top"
                                            >
                                                <IconButton
                                                    edge="end"
                                                    size="large"
                                                    style={{ display: "flex" }}
                                                    aria-label="favourite"
                                                    onClick={handleListItemChange(
                                                        {
                                                            ...item,
                                                            favourited:
                                                                !item.favourited,
                                                        }
                                                    )}
                                                >
                                                    {item.favourited ? (
                                                        <FavoriteIcon />
                                                    ) : (
                                                        <FavoriteBorderIcon />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {editList && (
                                            <Tooltip
                                                title="Delete item"
                                                placement="top"
                                            >
                                                <IconButton
                                                    edge="end"
                                                    size="large"
                                                    style={{
                                                        display: "flex",
                                                        color: appColors.lightRed,
                                                    }}
                                                    aria-label="remove item"
                                                    onClick={() =>
                                                        handleRemoveListItem(
                                                            item
                                                        )
                                                    }
                                                >
                                                    <RemoveCircleIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </div>
                                }
                                disablePadding
                            >
                                <ListItemButton
                                    role={undefined}
                                    onClick={handleListItemChange({
                                        ...item,
                                        checked: !item.checked,
                                    })}
                                    dense
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={item.checked}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                "aria-labelledby": labelId,
                                            }}
                                            sx={{
                                                color: appColors.almostWhite,
                                                "&.Mui-checked": {
                                                    color: appColors.lightBlue,
                                                },
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={labelId}
                                        primary={item.name}
                                        className="App-checkbox"
                                    />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </>
    );
}
