import { Button } from "@mui/material";
import CircularIndeterminate from "../CircularProgress/CircularProgress";
import { Chance } from "chance";
import Casino from "@mui/icons-material/Casino";
import { appColors } from "../../styles";
import {
    getFavouritedListItems,
    List,
    getCheckedListItems,
    getCheckedFavouritedListItems,
    ListItem,
} from "../../Models/List";
import { FavouritesChanceMessage } from "../FavouritesChanceMessage/FavouritesChanceMessage";

const chance = new Chance();

export const RandomResultDisplay = (props: {
    randomListItem: string;
    isGenerating: boolean;
    setIsGenerating: (value: boolean) => void;
    setRandomListItem: (value: string) => void;
    currentList: List;
    handleSetCurrentList: (value: List) => void;
    chanceNextListItemIsFavourite: number;
}) => {
    const onRandomize = () => {
        const favouritedListItems = getFavouritedListItems(props.currentList);
        const checkedListItems = getCheckedListItems(props.currentList);
        if (
            props.currentList.isFavouritesMultiplierEnabled &&
            favouritedListItems.length > 0
        ) {
            const checkedFavouritedListItems = getCheckedFavouritedListItems(
                props.currentList
            );
            const ListItemsToRandomize = [
                checkedListItems,
                ...Array.from(
                    { length: props.currentList.favouritesMultiplier - 1 },
                    () => checkedFavouritedListItems
                ).flat(),
            ].flat();
            randomise(ListItemsToRandomize);
        } else {
            randomise(checkedListItems);
        }
    };

    const randomise = (listItemsToRandomise: ListItem[]) => {
        props.setIsGenerating(true);
        setTimeout(() => {
            const randomListItem =
                listItemsToRandomise[
                    chance.integer({
                        min: 0,
                        max: listItemsToRandomise.length - 1,
                    })
                ];
            props.setRandomListItem(randomListItem.name);
            if (props.currentList.isRemoveRandomListItemEnabled) {
                props.handleSetCurrentList({
                    ...props.currentList,
                    items: props.currentList.items.map((item) =>
                        item.name === randomListItem.name
                            ? { ...item, checked: false }
                            : item
                    ),
                });
            }
            props.setIsGenerating(false);
        }, 1000);
    };

    return (
        <>
            <div style={{ paddingTop: "20px", paddingBottom: "100px" }}>
                What will it be from {props.currentList.name}...?
            </div>
            <div
                style={{
                    paddingBottom: "100px",
                    fontWeight: "bold",
                    fontSize: "calc(10px + 10vmin)",
                }}
            >
                {props.isGenerating ? (
                    <CircularIndeterminate />
                ) : (
                    props.randomListItem
                )}
            </div>
            <div style={{ marginBottom: "20px" }}>
                <Button
                    startIcon={<Casino />}
                    sx={{ backgroundColor: appColors.lightGrey }}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => onRandomize()}
                    disabled={
                        getCheckedListItems(props.currentList).length === 0
                    }
                >
                    Roll the dice
                </Button>
            </div>
            <FavouritesChanceMessage
                chanceNextListItemIsFavourite={
                    props.chanceNextListItemIsFavourite
                }
            />
        </>
    );
};
