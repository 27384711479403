import { Typography } from "@mui/material";
import { appColors } from "../../styles";

export const FavouritesChanceMessage = (props: {
    chanceNextListItemIsFavourite: number;
}) => {
    return (
        <Typography
            fontStyle={"italic"}
            fontSize={"0.8rem"}
            color={
                props.chanceNextListItemIsFavourite >= 50
                    ? appColors.lightGreen
                    : props.chanceNextListItemIsFavourite >= 25
                    ? appColors.lightYellow
                    : appColors.lightRed
            }
        >
            {`The likelihood of a favourite item being selected on the next roll is ${props.chanceNextListItemIsFavourite}%`}
        </Typography>
    );
};
